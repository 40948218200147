import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const BrandingContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  color: #ffffff;
  background-color: ${themeGet('primary.0', '#EE3932')};
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;

  span {
    margin-bottom: 17px;
  }
`;

export const Logo = styled.img`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 11px;
  height: 40px;
`;
