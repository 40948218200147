import React from 'react';
import LoaderWrapper from './Loader.style';
import defaultTheme from '../../assets/styles';

export const Loader = ({ fill, className }: { fill?: string; className?: string }) => {
  const addAllClasses = ['loader'];

  if (className) {
    addAllClasses.push(className);
  }

  return (
    <LoaderWrapper className={addAllClasses.join(' ')}>
      <svg enableBackground="new 0 0 0 0">
        <circle fill={fill} stroke="none" cx="6" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle fill={fill} stroke="none" cx="26" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle fill={fill} stroke="none" cx="46" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </circle>
      </svg>
    </LoaderWrapper>
  );
};

Loader.defaultProps = {
  fill: defaultTheme.primary[0],
};
