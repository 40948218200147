import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sticky, { Status } from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import { Navbar } from 'components/Navbar';
import { Logo } from 'components/Logo';
import { Text } from 'components/Text';
import { Loader } from 'components/Loader';
import { useWindowSize } from 'utils/hooks';
import { getUserInfo, setUserLanguage } from 'utils/helpers';
import { AuthMenu, MainMenu, ProfileMenu } from './index';
import { IUser } from 'interfaces';
import { ProfileState } from 'modules/Profile/Profile.interfaces';
import { EventsState } from 'modules/Events/Event.interfaces';
import { AuthState } from 'modules/Auth/Auth.interfaces';
import { ContainerWrapper } from 'assets/styles/Container.style';
import HeaderWrapper, { AvatarInfo, AvatarWrapper, CloseDrawer, LogoArea, MobileNavbar } from './Header.style';
import LanguageSelector from '../../../components/LanguageSelector';
import { GlobalState, ILanguage } from '../../../modules/Global/Global.interfaces';
import { handleOpenPlayer } from '../../../modules/Events/Events.actions';
import { setHomePageState } from '../../../modules/Global/Global.actions';
import envVariables from '../../../constant';
import { LanguageSelectorPages } from '../../../modules/Global/Constants';
import { matchPath } from 'react-router-dom';
import { ProfileAvatar } from '../../../components/Navbar/Navbar.style';

const MobileMenu = lazy(() =>
  import('./index').then((module) => ({
    default: module.MobileMenu,
  })),
);

const LogoIcon = () => (
  <svg width="25" height="27.984" viewBox="0 0 25 27.984">
    <g transform="translate(0 0)">
      <path
        d="M25.45,2.767a34.5,34.5,0,0,0-4,1.143,35.262,35.262,0,0,0-3.771,1.545,26.069,26.069,0,0,0-3.179,1.8,26.068,26.068,0,0,0-3.191-1.8A35.262,35.262,0,0,0,7.54,3.909,34.5,34.5,0,0,0,3.55,2.767L2,2.45V17.94a12.5,12.5,0,1,0,25,0V2.45ZM14.5,10.492c2.339,1.96,3.522,4.19,3.512,6.608a3.512,3.512,0,1,1-7.024,0h0C10.98,14.66,12.162,12.442,14.5,10.492Zm9.913,7.448a9.915,9.915,0,0,1-19.831,0V5.69a31.8,31.8,0,0,1,7.748,3.259,13.43,13.43,0,0,0-2.344,2.737A9.929,9.929,0,0,0,8.4,17.095a6.1,6.1,0,1,0,12.2,0,9.932,9.932,0,0,0-1.587-5.412,13.427,13.427,0,0,0-2.346-2.742,29.737,29.737,0,0,1,5.586-2.577c.819-.284,1.559-.51,2.158-.675Z"
        transform="translate(-2 -2.45)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const Header = ({ location = {} }: { location?: any }) => {
  const dispatch = useDispatch();
  const { homePageState } = useSelector(({ globalReducer }: { globalReducer: GlobalState }) => globalReducer);
  const { loggedIn } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const token = localStorage.getItem('token');

  const { userInfo } = useSelector(({ profileReducer }: { profileReducer: ProfileState }) => profileReducer);
  const { userData } = useSelector(({ eventsReducer }: { eventsReducer: EventsState }) => eventsReducer);
  const [userInfoLocal, setUserInfoLoca] = useState<IUser>();

  const [withLanguageSelector, setWithLanguageSelector] = useState(false);
  const [headerState, setHeaderState] = useState(0);

  useEffect(() => {
    const isLanguageSelectorPage = LanguageSelectorPages.some((route) =>
      matchPath(window.location.pathname, {
        path: route,
        exact: true,
      }),
    );

    setWithLanguageSelector(isLanguageSelectorPage);
  }, [window.location.pathname]);
  const sidebarHandler = () => {
    setState(!state);
  };

  useEffect(() => {
    const user = userInfo || getUserInfo();
    setUserInfoLoca(user);
  }, [userInfo, userData]);

  const handleLanguageSelect = (selectedLanguage: ILanguage) => {
    setUserLanguage(selectedLanguage);
    dispatch(handleOpenPlayer.request({ list: [], account: null, index: 0 }));
    dispatch(
      setHomePageState({
        ...homePageState,
        selectedLanguage: selectedLanguage,
      }),
    );
  };

  const headerType = location.pathname === '/' ? 'transparent' : 'default';

  const onStateChange = (state: Status) => {
    setHeaderState(state.status);
  };

  return (
    <Sticky
      top={headerType === 'transparent' ? -1 : 0}
      innerZ={1000}
      activeClass="isHeaderSticky"
      onStateChange={onStateChange}
    >
      <HeaderWrapper className={headerState == 2 ? 'fixed' : 'default'}>
        {width && width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo withLink linkTo="/" src={envVariables.logo} title="Logo" className={'desktop-header'} />
                {withLanguageSelector && <LanguageSelector handleLanguageSelect={handleLanguageSelect} />}
              </>
            }
            navMenu={<MainMenu />}
            authMenu={!loggedIn && !token && <AuthMenu onClose={sidebarHandler} />}
            isLogin={loggedIn || Boolean(token)}
            profileMenu={<ProfileMenu />}
            headerType={headerType}
          />
        ) : (
          <MobileNavbar as={ContainerWrapper} className={headerType}>
            <LogoArea>
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo withLink linkTo="/" src={envVariables.logo} title="Logo" className={'mobile-header'} />
              </>
              {withLanguageSelector && <LanguageSelector handleLanguageSelect={handleLanguageSelect} />}
            </LogoArea>
            <Button className={`hamburg-btn ${state ? 'active' : ''}`} onClick={sidebarHandler} aria-label={'menu'}>
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width={width}
              className="mobile-header"
              visible={state}
              destroyOnClose
            >
              {loggedIn || token ? (
                <AvatarWrapper>
                  <ProfileAvatar>
                    <img src={envVariables.profileIcon} />
                  </ProfileAvatar>
                  <AvatarInfo>
                    <Text as="h3" content={userInfoLocal ? userInfoLocal.screen_name : 'You are not logged in'} />
                  </AvatarInfo>
                  <CloseDrawer>
                    <button onClick={sidebarHandler}>
                      <IoIosClose />
                    </button>
                  </CloseDrawer>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" onClose={sidebarHandler} />
              )}
              <Suspense fallback={<Loader />}>
                <MobileMenu className="main-menu" onClose={sidebarHandler} />
              </Suspense>
            </Drawer>
          </MobileNavbar>
        )}
      </HeaderWrapper>
    </Sticky>
  );
};
