import { createApiAction } from 'utils/helpers';

export const POST_EVENT_VIEW = 'POST_EVENT_VIEW';
export const POST_WIDGET_VIEW = 'POST_WIDGET_VIEW';
export const POST_CONTRIBUTOR_VIEW = 'POST_CONTRIBUTOR_VIEW';
export const POST_AUDIO_PLAYED = 'POST_AUDIO_PLAYED';
export const POST_AUDIO_PLAYED_SUCCESS = 'POST_AUDIO_PLAYED_SUCCESS';
export const PUT_AUDIO_STOPPED = 'PUT_AUDIO_STOPPED';
export const POST_BOOKING_LEAD = 'POST_BOOKING_LEAD';
export const LIST_EVENTS_ONLINE = 'LIST_EVENTS_ONLINE';
export const LIST_EVENTS_ONLINE_SUCCESS = 'LIST_EVENTS_ONLINE_SUCCESS';
export const LIST_PRODUCTIONS = 'LIST_PRODUCTIONS';
export const LIST_PRODUCTIONS_SUCCESS = 'LIST_PRODUCTIONS_SUCCESS';
export const LIST_CONTRIBUTOR_VISITS = 'LIST_CONTRIBUTOR_VISITS';
export const LIST_CONTRIBUTOR_VISITS_SUCCESS = 'LIST_CONTRIBUTOR_VISITS_SUCCESS';
export const LIST_WIDGET_VISITS = 'LIST_WIDGET_VISITS';
export const LIST_WIDGET_VISITS_SUCCESS = 'LIST_WIDGET_VISITS_SUCCESS';
export const GET_GLOBAL_STATS = 'GET_GLOBAL_STATS';
export const GET_GLOBAL_STATS_SUCCESS = 'GET_GLOBAL_STATS_SUCCESS';
export const LIST_EVENTS_VIEW = 'LIST_EVENTS_VIEW';
export const LIST_EVENTS_VIEW_SUCCESS = 'LIST_EVENTS_VIEW_SUCCESS';
export const LIST_PODCAST_PLAYED = 'LIST_PODCAST_PLAYED';
export const LIST_PODCAST_PLAYED_SUCCESS = 'LIST_PODCAST_PLAYED_SUCCESS';
export const LIST_LISTENING_AVG = 'LIST_LISTENING_AVG';
export const LIST_LISTENING_AVG_SUCCESS = 'LIST_LISTENING_AVG_SUCCESS';
export const LIST_BOOKING_LEADS = 'LIST_BOOKING_LEADS';
export const LIST_BOOKING_LEADS_SUCCESS = 'LIST_BOOKING_LEADS_SUCCESS';

export const postEventView = createApiAction(POST_EVENT_VIEW);
export const postWidgetView = createApiAction(POST_WIDGET_VIEW);
export const postContributorView = createApiAction(POST_CONTRIBUTOR_VIEW);
export const postAudioPlayed = createApiAction(POST_AUDIO_PLAYED);
export const putAudioStopped = createApiAction(PUT_AUDIO_STOPPED);
export const postBookingLead = createApiAction(POST_BOOKING_LEAD);
export const listEventsOnline = createApiAction(LIST_EVENTS_ONLINE);
export const listProductions = createApiAction(LIST_PRODUCTIONS);
export const listContributorVisits = createApiAction(LIST_CONTRIBUTOR_VISITS);
export const listWidgetVisits = createApiAction(LIST_WIDGET_VISITS);
export const getGlobalStats = createApiAction(GET_GLOBAL_STATS);
export const listEventView = createApiAction(LIST_EVENTS_VIEW);
export const listPodcastsPlayed = createApiAction(LIST_PODCAST_PLAYED);
export const listListeningAvg = createApiAction(LIST_LISTENING_AVG);
export const listBookingLeads = createApiAction(LIST_BOOKING_LEADS);
