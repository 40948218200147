import { axiosInstance } from 'services/api';
import { axiosInstance as anonAxiosInstance } from 'services/anonApi';
import { IOfferDescription } from './Pricing.interfaces';

export default {
  async getPlans() {
    return anonAxiosInstance.get('payment/plan/');
  },
  async getPacks() {
    return anonAxiosInstance.get('payment/pack/');
  },
  async getPaymentPack(id: number) {
    return axiosInstance.get(`payment/pack/${id}/buy/`);
  },
  async getPaymentPlan(id: number) {
    return axiosInstance.get(`payment/plan/${id}/subscribe/`);
  },
  async getCurrentPlan() {
    return axiosInstance.get('payment/plan/current/');
  },
  async deleteCurrentPlan() {
    return axiosInstance.get('payment/plan/cancel/subscribe/');
  },
  async getPaymentFailed(params: { invoice: string }) {
    return axiosInstance.get('payment/failed-cahrge/', { params });
  },

  async getOffersDescription() {
    return anonAxiosInstance.get('payment/offerMessage');
  },

  async putOffersDescriptions(data: IOfferDescription[]) {
    return axiosInstance.put('payment/offerMessage/', data);
  },
};
