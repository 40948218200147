import React, { ComponentClass, FunctionComponent } from 'react';
import styled from 'styled-components';
import { fontFamily, fontWeight, letterSpacing, lineHeight, textAlign } from 'styled-system';
import { base, themed } from '../Base';

const TextWrapper = styled('p')(base, fontFamily, fontWeight, textAlign, lineHeight, letterSpacing, themed('Text'), {
  whiteSpace: 'pre-line',
});
export const Text = ({
  content,
  ...props
}: {
  content: React.ReactChildren | React.ReactChild;
  as?: string | ComponentClass<any, any> | FunctionComponent<any>;
}): JSX.Element => <TextWrapper {...props}>{content}</TextWrapper>;
