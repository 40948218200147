import { createApiAction } from 'utils/helpers';

export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE';

export const GET_ALL_PACK = 'GET_ALL_PACK';
export const GET_ALL_PACK_SUCCESS = 'GET_ALL_PACK_SUCCESS';
export const GET_ALL_PACK_FAILURE = 'GET_ALL_PACK_FAILURE';

export const GET_PAYMENT_PACK = 'GET_PAYMENT_PACK';
export const GET_PAYMENT_PACK_SUCCESS = 'GET_PAYMENT_PACK_SUCCESS';
export const GET_PAYMENT_PACK_FAILURE = 'GET_PAYMENT_PACK_FAILURE';

export const GET_PAYMENT_PLAN = 'GET_PAYMENT_PLAN';
export const GET_PAYMENT_PLAN_SUCCESS = 'GET_PAYMENT_PLAN_SUCCESS';
export const GET_PAYMENT_PLAN_FAILURE = 'GET_PAYMENT_PLAN_FAILURE';

export const GET_CURRENT_PLAN = 'GET_CURRENT_PLAN';
export const GET_CURRENT_PLAN_SUCCESS = 'GET_CURRENT_PLAN_SUCCESS';
export const GET_CURRENT_PLAN_FAILURE = 'GET_CURRENT_PLAN_FAILURE';

export const DELETE_CURRENT_PLAN = 'DELETE_CURRENT_PLAN';
export const DELETE_CURRENT_PLAN_SUCCESS = 'DELETE_CURRENT_PLAN_SUCCESS';
export const DELETE_CURRENT_PLAN_FAILURE = 'DELETE_CURRENT_PLAN_FAILURE';

export const GET_PAYMENT_FAILED = 'GET_PAYMENT_FAILED';
export const GET_PAYMENT_FAILED_SUCCESS = 'GET_PAYMENT_FAILED_SUCCESS';
export const GET_PAYMENT_FAILED_FAILURE = 'GET_PAYMENT_FAILED_FAILURE';

export const SET_ID_OF_PLAN = 'SET_ID_OF_PLAN';
export const CLEAR_SESSION_ID = 'CLEAR_SESSION_ID';
export const CLEAR_CURRENT_PLAN = 'CLEAR_CURRENT_PLAN';

export const GET_OFFERS_DESCRIPTION = 'GET_OFFERS_DESCRIPTION';
export const GET_OFFERS_DESCRIPTION_SUCCESS = 'GET_OFFERS_DESCRIPTION_SUCCESS';
export const GET_OFFERS_DESCRIPTION_FAILURE = 'GET_OFFERS_DESCRIPTION_FAILURE';

export const PUT_OFFERS_DESCRIPTIONS = 'PUT_OFFERS_DESCRIPTIONS';
export const PUT_OFFERS_DESCRIPTIONS_SUCCESS = 'PUT_OFFERS_DESCRIPTIONS_SUCCESS';
export const PUT_OFFERS_DESCRIPTIONS_FAILURE = 'PUT_OFFERS_DESCRIPTIONS_FAILURE';

export const getPlans = createApiAction(GET_PLANS);
export const getPacks = createApiAction(GET_ALL_PACK);
export const getPaymentPack = createApiAction(GET_PAYMENT_PACK);
export const getPaymentPlan = createApiAction(GET_PAYMENT_PLAN);
export const getCurrentPlan = createApiAction(GET_CURRENT_PLAN);
export const deleteCurrentPlan = createApiAction(DELETE_CURRENT_PLAN);
export const setPlanId = createApiAction(SET_ID_OF_PLAN);
export const getPaymentFailed = createApiAction(GET_PAYMENT_FAILED);
export const getOffersDescriptions = createApiAction(GET_OFFERS_DESCRIPTION);
export const putOffersDescriptions = createApiAction(PUT_OFFERS_DESCRIPTIONS);
