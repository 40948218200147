import Swal from 'sweetalert2';
import defaultTheme from 'assets/styles/index';
import i18n from 'i18n';

import frFR from 'antd/lib/locale/fr_FR';
import enGB from 'antd/lib/locale/en_GB';
import { EventStatus, StatusOptions, UserRoles } from 'constant/Events';
import {
  ADMIN,
  BASE_USER,
  CONTRIBUTOR,
  IEventDetails,
  IPermissions,
  IStatus,
  IUser,
  PRODUCER,
  REDACTOR,
  SUPER_ADMIN,
} from 'interfaces';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { DefaultLanguage, SupportedLanguages } from '../modules/Global/Constants';
import { ILanguage } from '../modules/Global/Global.interfaces';
import dayjs from 'dayjs';
import { pathToRegexp } from 'path-to-regexp';

export const createApiAction = (type: string) => ({
  request: (payload?: unknown) => ({ type, payload }),
  success: (payload?: unknown) => ({ type: `${type}_SUCCESS`, payload }),
  failure: (error?: unknown) => ({ type: `${type}_FAILURE`, error }),
});

export const getPermissions = (): IPermissions => {
  const user = localStorage.getItem('user');
  if (user) {
    const role = JSON.parse(user).role;
    return {
      superAdmin: role === SUPER_ADMIN,
      admin: role === ADMIN,
      redactor: role === REDACTOR,
      producer: role === PRODUCER,
      baseUser: role === BASE_USER,
      contributor: role == CONTRIBUTOR,
    };
  }
  return {
    superAdmin: false,
    redactor: false,
    producer: false,
    baseUser: false,
    admin: false,
    contributor: false,
  };
};

export const getUserInfo = (field?: string) => {
  const user = localStorage.getItem('user');
  if (!user && field) return null;
  if (!user) return {};
  if (field) return JSON.parse(user)[field];
  return JSON.parse(user);
};

export const setUserLanguage = (language: ILanguage) => {
  localStorage.setItem('i18Lng', JSON.stringify(language));
  i18n.changeLanguage(language.lng);
  dayjs.locale(language.lng) && moment.locale(language.lng);
};

export const getUserLanguage = (): ILanguage => {
  const lng = localStorage.getItem('i18Lng');
  if (lng) return JSON.parse(lng) as ILanguage;
  const browserLanguage = navigator.language;
  const language = SupportedLanguages.find((l) => l.code === browserLanguage) || DefaultLanguage;
  setUserLanguage(language);
  return language;
};

export const setAntDLocale = () => {
  const language = getUserLanguage();
  switch (language.lng) {
    case 'en':
      return enGB;
    case 'fr':
      return frFR;
    default:
      return frFR;
  }
};

export const handleError = (error: any) => {
  i18n.loadNamespaces('common', () => {
    if (!error.response || error.response.status === 500) {
      showError({ text: i18n.t('Error on server! Please try again!') });
    } else if (error.response.status === 400) {
      if (error.response.data && Array.isArray(error.response.data) && error.response.data[0]) {
        showError({ text: error.response.data[0] });
      } else if (error.response.data) {
        showError({ text: `${Object.keys(error.response.data)[0]}: ${Object.values(error.response.data)[0]}` });
      } else {
        showError({ text: i18n.t('Incorrect data!') });
      }
    } else if (error.response.status === 401) {
      return;
    } else {
      showError({ text: i18n.t('Something went wrong! Please try again!') });
    }
  });
};

export const isMobile = (width: number | undefined) => {
  const mobile = +defaultTheme.breakpoints[0].replace('px', '');
  if (width) {
    return mobile > width;
  }
  return false;
};
export const isTablet = (width: number | undefined) => {
  const mobile = +defaultTheme.breakpoints[0].replace('px', '');
  const tablet = +defaultTheme.breakpoints[2].replace('px', '');
  if (width) {
    return mobile < width && tablet > width;
  }
  return false;
};

export const updateUserInfo = (newInfo: any) => {
  const oldUserStored = localStorage.getItem('user');
  if (oldUserStored) {
    const newObj = JSON.parse(oldUserStored);
    Object.assign(newObj, newInfo);
    localStorage.setItem('user', JSON.stringify(newObj));
  } else localStorage.setItem('user', newInfo);
};

export const urlB64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

interface showMsgProps {
  text: string;
  title?: string;
}

export const showSuccess = ({ text, title }: showMsgProps) => {
  Swal.fire({ title, text, icon: 'success' });
};
export const showError = ({ text, title }: showMsgProps) => {
  Swal.fire({ title, text, icon: 'error' });
};
export const showInfo = ({ text, title }: showMsgProps) => {
  Swal.fire({ title, text, icon: 'info' });
};
export const showWarning = ({ text, title }: showMsgProps) => {
  Swal.fire({ title, text, icon: 'warning' });
};
export const showConfirmModal = ({
  text,
  title,
  onSuccess,
  onCancel,
}: {
  text: string;
  title?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
}) => {
  Swal.fire({
    title,
    text,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: defaultTheme.success[0],
    cancelButtonColor: defaultTheme.error[0],
    confirmButtonText: i18n.t('OK'),
  }).then((result) => {
    if (result.isConfirmed && onSuccess) {
      onSuccess();
    } else if (onCancel) {
      onCancel();
    }
  });
};

export const parseSubscrObj = (text: string) => {
  const parsed = text.replaceAll("'", '"').split(', "expirationTime"')[0] + '}';
  const obj = { ...JSON.parse(parsed), expirationTime: null };
  return obj;
};

export const srcToFile = (src: string, fileName: string, mimeType: string): Promise<File | undefined> => {
  return fetch(src)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], fileName, { type: mimeType }))
    .catch((error) => {
      if (!src.includes('s3.eu-west-3.')) {
        const retryUrl: string = src.replace('s3.', 's3.eu-west-3.');
        return srcToFile(retryUrl, fileName, mimeType);
      }
      // Rethrow error if it's not handled
      console.log(error);
      return undefined;
    });
};

export const readFile = (file?: File | Blob) => {
  if (file) {
    return new Promise<string | ArrayBuffer | null>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
};

export const showErrorOnDisabledEventAction = (
  eventDetails: IEventDetails,
  action: 'approve' | 'reject' | 'delete',
) => {
  let error = i18n.t('Event cannot be approved');
  switch (action) {
    case 'approve':
      if ([EventStatus.CREATED, EventStatus.ONLINE, EventStatus.REJECTED].includes(eventDetails.status)) {
        error = i18n.t(`Event is in status ${eventDetails.status}`);
      }
      if (!eventDetails.audio_url) {
        error = i18n.t('Event requires production, but is not produced');
      }
      break;

    case 'reject':
      if ([EventStatus.CREATED, EventStatus.ONLINE, EventStatus.REJECTED].includes(eventDetails.status)) {
        error = i18n.t(`Event is in status ${eventDetails.status}`);
      }
      break;
    case 'delete':
      if (eventDetails.status === EventStatus.ONLINE) {
        error = i18n.t(`Event is in status ${eventDetails.status}`);
      }
  }
  showError({ text: error });
};

export const getStatusOptions = (user: IUser) => {
  let validStatuses = [];
  if (user.entity.plan.type == 'delegated_zone' && user.role === 'redactor') {
    validStatuses = StatusOptions['moderator'];
  } else {
    validStatuses = StatusOptions[user.role];
  }
  return validStatuses.map((status) => ({
    label: i18n.t(status),
    value: status,
  }));
};

export const getEventStatus = (user: IUser, status: IStatus): IStatus => {
  switch (status) {
    case 'IN_PRODUCTION':
    case 'PRODUCED':
      return user.role === 'contributor' ? 'ON_HOLD' : status;

    case 'REJECTED_PRODUCTION':
      if (user.role === 'contributor') {
        return 'ON_HOLD';
      } else if (user.role === 'redactor' && user.entity?.plan?.type === 'delegated_zone') {
        return 'IN_PRODUCTION';
      } else {
        return status;
      }

    default:
      return status;
  }
};

export const metaDescription = (description: string | undefined, limit = 250): string => {
  if (!description) return '';
  if (description.length <= 250) {
    return description;
  }
  const subString = description.slice(0, limit - 1);
  return subString.slice(0, subString.lastIndexOf(' ')) + '&hellip;';
};

export const downloadFile = async (url: string, name: string, contentType: 'audio/mp3' | 'video/mp4') => {
  await fetch(url)
    .then((response) => {
      return response.blob();
    })
    .catch(() => {
      if (!url.includes('s3.eu-west-3.')) {
        const retryUrl: string = url.replace('s3.', 's3.eu-west-3.');
        downloadFile(retryUrl, name, contentType);
      }
    })
    .then((blob) => {
      if (blob) {
        fileDownload(blob, name, contentType);
      }
    });
};

export const getEventDateString = (startDate: string, endDate: string, format = 'DD MMMM YYYY'): string => {
  if (startDate && endDate) {
    const language = getUserLanguage();
    const start = moment(startDate + 'Z').locale(language.lng);
    const end = moment(endDate + 'Z').locale(language.lng);
    if (start.date() === end.date() && start.month() === end.month() && start.year() === end.year()) {
      return `${start.format(format)}`;
    } else if (start.year() === end.year()) {
      return `${start.format(format.replaceAll('Y', ''))} - ${end.format(format)}`;
    }
    return `${start.format(format)} - ${end.format(format)}`;
  } else {
    return i18n.t('Ongoing Event');
  }
};

export const objectToFormData = (obj: any, rootName = '', formData = new FormData()): FormData => {
  const appendFormData = (data: any, name: any) => {
    if (data instanceof File || data instanceof Blob) {
      formData.append(name, data);
    } else if (typeof data === 'boolean' || typeof data === 'number' || typeof data === 'string') {
      formData.append(name, data.toString());
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        objectToFormData(data[i], `${name}[${i}]`, formData);
      }
    } else if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          objectToFormData(data[key], name === '' ? key : `${name}.${key}`, formData);
        }
      }
    }
  };

  appendFormData(obj, rootName);

  return formData;
};

export const isExcludedRoute = (path: string, customerRoutes: any) => {
  if (!customerRoutes) {
    return false;
  }

  const { excludedRoutes } = customerRoutes;

  return excludedRoutes.some((pattern: string) => {
    if (pattern === '^/@.*$') {
      if (typeof path === 'string') {
        return path.startsWith('/@');
      }
      return false;
    } else if (pattern === '/event/*') {
      if (typeof path === 'string') {
        return path.startsWith('/event/');
      }
      return false;
    }
    const regexp = pathToRegexp(pattern);
    return regexp.test(path);
  });
};

export const getRoleOptions = (permissions: any): { label: string; value: string }[] => {
  let options: { label: string; value: string }[] = [];
  if (permissions.superAdmin) {
    options = [
      { label: i18n.t('User'), value: UserRoles.BASE_USER },
      { label: i18n.t('Contributor'), value: UserRoles.CONTRIBUTOR },
      { label: i18n.t('Producer'), value: UserRoles.PRODUCER },
      { label: i18n.t('Redactor'), value: UserRoles.REDACTOR },
      { label: i18n.t('Admin'), value: UserRoles.ADMIN },
    ];
  } else if (permissions.admin) {
    options = [
      { label: i18n.t('User'), value: UserRoles.BASE_USER },
      { label: i18n.t('Contributor'), value: UserRoles.CONTRIBUTOR },
      { label: i18n.t('Moderator'), value: UserRoles.REDACTOR },
    ];
  } else if (permissions.redactor) {
    options = [
      { label: i18n.t('User'), value: UserRoles.BASE_USER },
      { label: i18n.t('Contributor'), value: UserRoles.CONTRIBUTOR },
    ];
  }
  return options;
};
