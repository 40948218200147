import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from 'i18n/translationEN.json';
import FR from 'i18n/translationFR.json';
import { SupportedLanguages } from './modules/Global/Constants';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    en: {
      translation: EN,
    },
    fr: {
      translation: FR,
    },
  },
  supportedLngs: SupportedLanguages.map((l) => l.lng),
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
