import { createApiAction } from 'utils/helpers';

export const GET_APIDAE_INFO = 'GET_APIDAE_INFO';
export const GET_APIDAE_INFO_SUCCESS = 'GET_APIDAE_INFO_SUCCESS';
export const GET_APIDAE_INFO_FAILURE = 'GET_APIDAE_INFO_FAILURE';

export const GET_TOURINSOFT_INFO = 'GET_TOURINSOFT_INFO';
export const GET_TOURINSOFT_INFO_SUCCESS = 'GET_TOURINSOFT_INFO_SUCCESS';
export const GET_TOURINSOFT_INFO_FAILURE = 'GET_TOURINSOFT_INFO_FAILURE';

export const GET_OPENAGENDA_INFO = 'GET_OPENAGENDA_INFO';
export const GET_OPENAGENDA_INFO_SUCCESS = 'GET_OPENAGENDA_INFO_SUCCESS';
export const GET_OPENAGENDA_INFO_FAILURE = 'GET_OPENAGENDA_INFO_FAILURE';

export const CLEAR_SIT_DATA = 'CLEAR_SIT_DATA';

export const getAPIDAEInfo = createApiAction(GET_APIDAE_INFO);
export const getTourinsoftInfo = createApiAction(GET_TOURINSOFT_INFO);
export const getOpenAgendaInfo = createApiAction(GET_OPENAGENDA_INFO);
