import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandingContainer, Logo } from './Branding.style';
import envVariables from '../../../constant';

const Branding: FC = () => {
  const { t } = useTranslation();
  const logoUrl = process.env.REACT_APP_BASE_URL;
  return (
    <BrandingContainer>
      <span>{t('Find all our events on')}</span>
      <a href={logoUrl} target="_blank" rel="noreferrer">
        <Logo src={envVariables.logoWhite} alt="logo" />
      </a>
    </BrandingContainer>
  );
};

export default Branding;
