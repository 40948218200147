import { ILanguage } from './Global.interfaces';
import { ROUTES } from '../../constant';

export const SupportedLanguages: ILanguage[] = [
  {
    id: 1,
    name: 'Français',
    code: 'fr-FR',
    lng: 'fr',
  },
  {
    id: 2,
    name: 'English',
    code: 'en-US',
    lng: 'en',
  },
];

export const DefaultLanguage: ILanguage = SupportedLanguages[0];

export const LanguageSelectorPages: string[] = [ROUTES.BASE.EVENTS, ROUTES.BASE.CONTRIBUTOR_PAGE];
