import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { useOnClickOutside } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import envVariables, { ROUTES } from 'constant';
import { logOut } from 'modules/Auth/Auth.actions';
import { ADMIN, BASE_USER, CONTRIBUTOR, IMenuList, PRODUCER, REDACTOR, SUPER_ADMIN } from 'interfaces';
import { RenderMenuList } from 'components/MenuList';
import { DropdownIcon, ProfileAvatar, ProfilePseudo } from '../../../components/Navbar/Navbar.style';
import defaultTheme from '../../../assets/styles';
import { BiSolidChevronDown, BiSolidChevronUp } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../../../utils/helpers';

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = getUserInfo();
  const [state, setState] = useState(false);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));

  const handleLogout = () => {
    dispatch(logOut.request());
    history.push(ROUTES.AUTH.LOGIN_PAGE);
  };

  const MenuList: IMenuList[] = [
    {
      key: 'AGENT_ACCOUNT_SETTINGS_PAGE',
      onClick: closeDropdown,
      exact: true,
      to: ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE,
      title: t('Account Settings'),
    },
    {
      key: 'MY_ENTITY',
      onClick: closeDropdown,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_ENTITY}`,
      title: t('My Entity'),
      permissions: [CONTRIBUTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'ENTITIES_EVENTS',
      onClick: closeDropdown,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}`,
      title: t('My events'),
      permissions: [CONTRIBUTOR],
    },
    {
      key: 'FAVORITES',
      onClick: closeDropdown,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.FAVORITES}`,
      title: t('My favorites'),
      permissions: [BASE_USER, CONTRIBUTOR],
    },
    {
      key: 'INVOICES',
      onClick: closeDropdown,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.INVOICES}`,
      title: t('Invoices'),
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'MY-INVOICES',
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_PLAN}`,
      title: t('My subscription'),
      onClick: closeDropdown,
      permissions: [CONTRIBUTOR],
    },
    {
      key: 'GENERAL_STATS',
      onClick: closeDropdown,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.GENERAL_STATISTICS}`,
      title: t('Statistics'),
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'USERS_ADMIN_PANEL',
      onClick: closeDropdown,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.USERS_ADMIN_PANEL}`,
      title: t('Users'),
      permissions: [REDACTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'ENTITIES_ADMIN_PANEL',
      onClick: closeDropdown,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.ENTITIES_ADMIN_PANEL}`,
      title: t('Entities'),
      permissions: [REDACTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'REDACTOR_PAGE',
      onClick: closeDropdown,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}`,
      title: t('Events'),
      permissions: [PRODUCER, REDACTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'TAGS',
      onClick: closeDropdown,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.TAGS}`,
      title: t('Tags'),
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'PHONETICS',
      onClick: closeDropdown,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.PHONETICS}`,
      title: t('Phonetics'),
      permissions: [SUPER_ADMIN],
    },
  ];

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className={`dropdown-handler ${state ? 'active' : 'inactive'}`} onClick={handleDropdown}>
        <ProfileAvatar>
          <img src={envVariables.profileIcon} />
        </ProfileAvatar>
        <ProfilePseudo>{userInfo.screen_name}</ProfilePseudo>
        <DropdownIcon>
          {state ? (
            <BiSolidChevronUp color={defaultTheme.primary[0]} size={13} />
          ) : (
            <BiSolidChevronDown color={defaultTheme.primary[0]} size={13} />
          )}
        </DropdownIcon>
      </div>

      <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
        {RenderMenuList(MenuList)}
        <Menu.Item key="5">
          <button onClick={handleLogout}>{t('Log Out')}</button>
        </Menu.Item>
      </Menu>
    </div>
  );
};
