import { createApiAction } from 'utils/helpers';
import { IEventPrice } from '../../interfaces';

export const OPEN_PLAYER = 'OPEN_PLAYER';

export const DUPLICATE_EVENT = 'DUPLICATE_EVENT';
export const CLEAR_DUPLICATE_EVENT = 'CLEAR_DUPLICATE_EVENT';
export const CLEAR_DETAILED_EVENTS = 'CLEAR_DETAILED_EVENTS';
export const CLEAR_ONE_EVENT = 'CLEAR_ONE_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_DETAILED_EVENTS = 'GET_DETAILED_EVENTS';
export const GET_DETAILED_EVENTS_SUCCESS = 'GET_DETAILED_EVENTS_SUCCESS';
export const GET_SINGLE_EVENT = 'GET_SINGLE_EVENT';
export const GET_SINGLE_EVENT_SUCCESS = 'GET_SINGLE_EVENT_SUCCESS';
export const GET_ONE_EVENT = 'GET_ONE_EVENT';
export const GET_ONE_EVENT_SUCCESS = 'GET_ONE_EVENT_SUCCESS';

export const SET_EVENT_STATUS = 'SET_EVENT_STATUS';
export const SET_EVENT_STATUS_SUCCESS = 'SET_EVENT_STATUS_SUCCESS';
export const EVENT_DETAIL_STATUS_UPDATE_SUCCESS = 'EVENT_DETAIL_STATUS_UPDATE_SUCCESS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const REJECT_EVENT = 'REJECT_EVENT';
export const PUT_EVENT_ONLINE = 'PUT_EVENT_ONLINE';
export const PUT_EVENT_OFFLINE = 'PUT_EVENT_OFFLINE';
export const REJECT_EVENT_DETAIL = 'REJECT_EVENT_DETAIL';
export const PUT_EVENT_DETAIL_ONLINE = 'PUT_EVENT_DETAIL_ONLINE';
export const PUT_EVENT_DETAIL_OFFLINE = 'PUT_EVENT_DETAIL_OFFLINE';

export const GET_CONTRIBUTOR = 'GET_CONTRIBUTOR';
export const GET_CONTRIBUTOR_SUCCESS = 'GET_CONTRIBUTOR_SUCCESS';

export const GET_CONTRIBUTOR_EVENTS = 'GET_CONTRIBUTOR_EVENTS';
export const GET_CONTRIBUTOR_EVENTS_SUCCESS = 'GET_CONTRIBUTOR_EVENTS_SUCCESS';

export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

export const POST_COMMENT = 'POST_COMMENT';

export const DELETE_COMMENT = 'DELETE_COMMENT';

export const PATCH_REPORT = 'PATCH_REPORT';
export const PATCH_REPORT_SUCCESS = 'PATCH_REPORT_SUCCESS';

export const CLEAR_CONTRIBUTOR = 'CLEAR_CONTRIBUTOR';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const CLEAR_SINGLE_EVENT = 'CLEAR_SINGLE_EVENT';

export const POST_PHONETIC = 'POST_PHONETIC';
export const POST_PHONETIC_SUCCESS = 'POST_PHONETIC_SUCCESS';

export const LIST_PHONETICS = 'LIST_PHONETICS';
export const LIST_PHONETICS_SUCCESS = 'LIST_PHONETICS_SUCCESS';

export const DELETE_PHONETIC = 'DELETE_PHONETIC';
export const DELETE_PHONETIC_SUCCESS = 'DELETE_PHONETIC_SUCCESS';

export const DOWNLOAD_USER_EVENTS_CSV = 'DOWNLOAD_USER_EVENTS_CSV';

export const GENERATE_AI_TRANSCRIPT = 'GENERATE_AI_TRANSCRIPT';
export const GENERATE_AI_TRANSCRIPT_SUCCESS = 'GENERATE_AI_TRANSCRIPT_SUCCESS';

export const GENERATE_AI_PODCAST = 'GENERATE_AI_PODCAST';
export const GENERATE_AI_PODCAST_SUCCESS = 'GENERATE_AI_PODCAST_SUCCESS';

export const handleOpenPlayer = createApiAction(OPEN_PLAYER);
export const duplicateEvent = createApiAction(DUPLICATE_EVENT);
export const getEventsList = createApiAction(GET_EVENTS);
export const getDetailedEvents = createApiAction(GET_DETAILED_EVENTS);
export const getContributor = createApiAction(GET_CONTRIBUTOR);
export const getSingleEvent = createApiAction(GET_SINGLE_EVENT);
export const createEvent = createApiAction(CREATE_EVENT);
export const updateEvent = createApiAction(UPDATE_EVENT);
export const deleteEvent = createApiAction(DELETE_EVENT);
export const rejectEvent = createApiAction(REJECT_EVENT);
export const putEventOnline = createApiAction(PUT_EVENT_ONLINE);
export const putEventOffline = createApiAction(PUT_EVENT_OFFLINE);
export const rejectEventDetail = createApiAction(REJECT_EVENT_DETAIL);
export const putEventDetailOnline = createApiAction(PUT_EVENT_DETAIL_ONLINE);
export const putEventDetailOffline = createApiAction(PUT_EVENT_DETAIL_OFFLINE);
export const getFavorites = createApiAction(GET_FAVORITES);
export const getContributorEvents = createApiAction(GET_CONTRIBUTOR_EVENTS);
export const getUserData = createApiAction(GET_USER_DATA);
export const postComment = createApiAction(POST_COMMENT);
export const deleteComment = createApiAction(DELETE_COMMENT);
export const patchReport = createApiAction(PATCH_REPORT);
export const postPhonetic = createApiAction(POST_PHONETIC);
export const listPhonetics = createApiAction(LIST_PHONETICS);
export const deletePhonetic = createApiAction(DELETE_PHONETIC);
export const getOneEvent = createApiAction(GET_ONE_EVENT);
export const downloadUserEventsCsv = createApiAction(DOWNLOAD_USER_EVENTS_CSV);

export const SET_EVENT_PRICES = 'SET_EVENT_PRICES';
export const setEventsPrices = (state: IEventPrice[]) => {
  return {
    type: SET_EVENT_PRICES,
    payload: state,
  };
};
export const GENERATE_EVENT_PRICES = 'GENERATE_EVENT_PRICES';
export const GENERATE_EVENT_PRICES_SUCCESS = 'GENERATE_EVENT_PRICES_SUCCESS';
export const generateEventPrices = createApiAction(GENERATE_EVENT_PRICES);
export const generateAITranscript = createApiAction(GENERATE_AI_TRANSCRIPT);
export const generateAIPodcast = createApiAction(GENERATE_AI_PODCAST);
