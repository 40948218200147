import styled from 'styled-components';
import { Dropdown } from 'antd';

export const FlagImage = styled.img`
  width: 24px;
  margin-right: 8px;
`;

export const LanguageDropdown = styled(Dropdown)`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  margin: 5px 5px 5px 40px;
  cursor: pointer;
  align-items: center;
`;

export const ArrowCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #efefef;
  border-radius: 50%;
  margin-right: 8px;
`;
