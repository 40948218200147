import Axios from 'axios';
import { getUserLanguage } from '../utils/helpers';

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  responseType: 'json',
  headers: { 'Accept-Language': getUserLanguage().code, 'X-Client-ID': process.env.REACT_APP_CLIENT_ID },
});

export { axiosInstance };
