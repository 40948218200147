import { SET_HOME_PAGE_STATE, UPDATE_IFRAME_PARAMS } from './Global.actions';
import { Reducer } from 'react';
import { GlobalActionTypes, GlobalState } from './Global.interfaces';
import { getUserLanguage } from '../../utils/helpers';

const initialState: GlobalState = {
  loading: false,
  error: null,
  iframeParams: { isIframe: false, contributorId: null },
  homePageState: { isMapVisible: false, filters: { page: 1 }, selectedLanguage: getUserLanguage() },
};

const globalReducer: Reducer<GlobalState, GlobalActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_IFRAME_PARAMS: {
      return {
        ...state,
        iframeParams: action.payload,
      };
    }
    case SET_HOME_PAGE_STATE: {
      return {
        ...state,
        homePageState: action.payload,
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
