import React, { useEffect, useState } from 'react';

const Footer = () => {
  const [FooterComponent, setFooterComponent] = useState<React.ComponentType | null>(null);
  const env = process.env.REACT_APP_ENV;
  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const component = await import(`./${env}/footer`);
        setFooterComponent(() => component.default);
      } catch (err) {
        const component = await import(`./anto/footer`);
        setFooterComponent(() => component.default);
      }
    };
    fetchFooter();
  }, []);

  return FooterComponent && <FooterComponent />;
};
export default Footer;
