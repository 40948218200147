import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { handleError } from 'utils/helpers';
import * as ActionTypes from './Pricing.actions';
import * as ActionTypesEvents from 'modules/Events/Events.actions';
import * as ActionInterface from './Pricing.interfaces';
import { GetOffersDescriptions, PutOffersDescriptions } from './Pricing.interfaces';
import Api from './Pricing.api';

function* getPlans() {
  try {
    const { data } = yield call(Api.getPlans);
    yield put(ActionTypes.getPlans.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPlans.failure(e));
  }
}

function* getPlansSaga() {
  yield takeLatest(ActionTypes.GET_PLANS, getPlans);
}

function* getPacks() {
  try {
    const { data } = yield call(Api.getPacks);
    yield put(ActionTypes.getPacks.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPacks.failure(e));
  }
}

function* getPacksSaga() {
  yield takeLatest(ActionTypes.GET_ALL_PACK, getPacks);
}

function* getPaymentPack({ payload }: ActionInterface.GetPaymentPackAction) {
  try {
    const { data } = yield call(Api.getPaymentPack, payload.id);
    yield put(ActionTypes.getPaymentPack.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPaymentPack.failure(e));
  }
}

function* getPaymentPackSaga() {
  yield takeLatest(ActionTypes.GET_PAYMENT_PACK, getPaymentPack);
}

function* getPaymentPlan({ payload }: ActionInterface.GetPaymentPlanAction) {
  try {
    const { data } = yield call(Api.getPaymentPlan, payload.id);
    yield put(ActionTypes.getPaymentPlan.success(data));
    yield put(ActionTypes.getCurrentPlan.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPaymentPlan.failure(e));
  }
}

function* getPaymentPlanSaga() {
  yield takeLatest(ActionTypes.GET_PAYMENT_PLAN, getPaymentPlan);
}

function* getCurrentPlan() {
  try {
    const { data } = yield call(Api.getCurrentPlan);
    yield put(ActionTypes.getCurrentPlan.success(data));
    yield put(ActionTypesEvents.getUserData.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getCurrentPlan.failure(e));
  }
}

function* getCurrentPlanSaga() {
  yield takeLatest(ActionTypes.GET_CURRENT_PLAN, getCurrentPlan);
}

function* deleteCurrentPlan() {
  try {
    const { data } = yield call(Api.deleteCurrentPlan);
    yield put(ActionTypes.deleteCurrentPlan.success(data));
    yield put(ActionTypesEvents.getUserData.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deleteCurrentPlan.failure(e));
  }
}

function* deleteCurrentPlanSaga() {
  yield takeLatest(ActionTypes.DELETE_CURRENT_PLAN, deleteCurrentPlan);
}

function* getPaymentFailed({ payload }: ActionInterface.GetPaymentFailed) {
  try {
    const { data } = yield call(Api.getPaymentFailed, payload);
    yield put(ActionTypes.getPaymentFailed.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPaymentFailed.failure(e));
  }
}

function* getPaymentFailedSaga() {
  yield takeLatest(ActionTypes.GET_PAYMENT_FAILED, getPaymentFailed);
}

function* putOffersDescriptions({ payload }: PutOffersDescriptions) {
  try {
    const { data } = yield call(Api.putOffersDescriptions, payload);
    yield put(ActionTypes.putOffersDescriptions.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.putOffersDescriptions.failure(e));
  }
}

function* putOffersDescriptionsSaga() {
  yield takeLatest(ActionTypes.PUT_OFFERS_DESCRIPTIONS, putOffersDescriptions);
}

function* getOffersDescriptions({}: GetOffersDescriptions) {
  try {
    const { data } = yield call(Api.getOffersDescription);
    yield put(ActionTypes.getOffersDescriptions.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getOffersDescriptions.failure(e));
  }
}

function* getOffersDescriptionsSaga() {
  yield takeLatest(ActionTypes.GET_OFFERS_DESCRIPTION, getOffersDescriptions);
}

export const pricingSaga = function* rootSaga() {
  yield all([
    fork(getPlansSaga),
    fork(getPacksSaga),
    fork(getPaymentPackSaga),
    fork(getPaymentPlanSaga),
    fork(getCurrentPlanSaga),
    fork(deleteCurrentPlanSaga),
    fork(getPaymentFailedSaga),
    fork(getOffersDescriptionsSaga),
    fork(putOffersDescriptionsSaga),
  ]);
};
