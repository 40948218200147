import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { getUserInfo } from 'utils/helpers';
import { IRole } from 'interfaces';

interface IStaticContext {
  statusCode?: number;
}

interface IRouter {
  path: string;
  exact?: boolean;
  component?:
    | React.ComponentType<RouteComponentProps<any, IStaticContext, unknown>>
    | React.ComponentType<any>
    | undefined;
  permissions?: IRole[];
  render?: any;
}

export const PermissionRoute = ({ permissions, path, exact = false, component, render }: IRouter) => {
  const role: IRole = getUserInfo('role');
  if (permissions && !permissions.includes(role)) {
    return null;
  }

  return <Route path={path} exact={exact} component={component} render={render} />;
};
