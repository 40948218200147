import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from './Statistics.actions';
import Api from './Statistics.api';
import * as Interfaces from './Statistics.interfaces';
import { handleError } from '../../utils/helpers';

function* postBookingLead({ payload }: Interfaces.PostBookingLeadAction) {
  try {
    const { iframeParams } = yield select((state) => state.globalReducer);
    yield call(Api.postBookingLead, { ...payload, is_from_widget: iframeParams.isIframe });
  } catch (e) {
    handleError(e);
  }
}

function* postBookingLeadSaga() {
  yield takeLatest(ActionTypes.POST_BOOKING_LEAD, postBookingLead);
}

function* listEventsOnline({ payload }: Interfaces.ListEventsOnlineAction) {
  try {
    const { data } = yield call(Api.listEventsOnline, payload);
    yield put(ActionTypes.listEventsOnline.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listEventsOnlineSaga() {
  yield takeLatest(ActionTypes.LIST_EVENTS_ONLINE, listEventsOnline);
}

function* listEventsView({ payload }: Interfaces.ListEventsViewAction) {
  try {
    const { data } = yield call(Api.listEventsView, payload);
    yield put(ActionTypes.listEventView.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listEventsViewSaga() {
  yield takeLatest(ActionTypes.LIST_EVENTS_VIEW, listEventsView);
}

function* listProductions({ payload }: Interfaces.ListProductionsAction) {
  try {
    const { data } = yield call(Api.listProductions, payload);
    yield put(ActionTypes.listProductions.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listProductionsSaga() {
  yield takeLatest(ActionTypes.LIST_PRODUCTIONS, listProductions);
}

function* listContributorVisits({ payload }: Interfaces.ListContributorVisitsAction) {
  try {
    const { data } = yield call(Api.listContributorVisits, payload);
    yield put(ActionTypes.listContributorVisits.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listContributorVisitsSaga() {
  yield takeLatest(ActionTypes.LIST_CONTRIBUTOR_VISITS, listContributorVisits);
}

function* listWidgetVisits({ payload }: Interfaces.ListWidgetVisitsAction) {
  try {
    const { data } = yield call(Api.listWidgetVisits, payload);
    yield put(ActionTypes.listWidgetVisits.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listWidgetVisitsSaga() {
  yield takeLatest(ActionTypes.LIST_WIDGET_VISITS, listWidgetVisits);
}

function* getGlobalStats({ payload }: Interfaces.GetGlobalStatsAction) {
  try {
    const { data } = yield call(Api.getGlobalStats, payload);
    yield put(ActionTypes.getGlobalStats.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* getGlobalStatsSaga() {
  yield takeLatest(ActionTypes.GET_GLOBAL_STATS, getGlobalStats);
}

function* listPodcastsPlayed({ payload }: Interfaces.ListPodcastsPlayedAction) {
  try {
    const { data } = yield call(Api.listPodcastsPlayed, payload);
    yield put(ActionTypes.listPodcastsPlayed.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listPodcastsPlayedSaga() {
  yield takeLatest(ActionTypes.LIST_PODCAST_PLAYED, listPodcastsPlayed);
}

function* listListeningAvg({ payload }: Interfaces.ListListeningAvgAction) {
  try {
    const { data } = yield call(Api.listListeningAvg, payload);
    yield put(ActionTypes.listListeningAvg.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listListeningAvgSaga() {
  yield takeLatest(ActionTypes.LIST_LISTENING_AVG, listListeningAvg);
}

function* listBookingLeads({ payload }: Interfaces.ListBookingLeadsAction) {
  try {
    const { data } = yield call(Api.listBookingLeads, payload);
    yield put(ActionTypes.listBookingLeads.success(data));
  } catch (e) {
    handleError(e);
  }
}

function* listBookingLeadsSaga() {
  yield takeLatest(ActionTypes.LIST_BOOKING_LEADS, listBookingLeads);
}

function* postEventView({ payload }: Interfaces.PostEventViewAction) {
  try {
    yield call(Api.postEventView, payload);
  } catch (e) {
    console.error(e);
  }
}

function* postEventViewSaga() {
  yield takeLatest(ActionTypes.POST_EVENT_VIEW, postEventView);
}

function* postWidgetView({ payload }: Interfaces.PostWidgetViewAction) {
  try {
    yield call(Api.postWidgetView, payload);
  } catch (e) {
    console.error(e);
  }
}

function* postWidgetViewSaga() {
  yield takeLatest(ActionTypes.POST_WIDGET_VIEW, postWidgetView);
}

function* postContributorView({ payload }: Interfaces.PostContributorViewAction) {
  try {
    yield call(Api.postContributorView, payload);
  } catch (e) {
    console.error(e);
  }
}

function* postContributorViewSaga() {
  yield takeLatest(ActionTypes.POST_CONTRIBUTOR_VIEW, postContributorView);
}

function* postAudioPlayed({ payload }: Interfaces.PostAudioPlayedAction) {
  try {
    const { iframeParams } = yield select((state) => state.globalReducer);
    const { data } = yield call(Api.postAudioPlayed, { ...payload, is_from_widget: iframeParams.isIframe });
    yield put(ActionTypes.postAudioPlayed.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* postAudioPlayedSaga() {
  yield takeLatest(ActionTypes.POST_AUDIO_PLAYED, postAudioPlayed);
}

function* putAudioStopped({ payload }: Interfaces.PutAudioStoppedAction) {
  try {
    const { iframeParams } = yield select((state) => state.globalReducer);
    yield call(Api.putAudioStopped, { ...payload, is_from_widget: iframeParams.isIframe });
  } catch (e) {
    console.error(e);
  }
}

function* putAudioStoppedSaga() {
  yield takeLatest(ActionTypes.PUT_AUDIO_STOPPED, putAudioStopped);
}

export const statisticSaga = function* rootSaga() {
  yield all([
    fork(postBookingLeadSaga),
    fork(postEventViewSaga),
    fork(postWidgetViewSaga),
    fork(postContributorViewSaga),
    fork(postAudioPlayedSaga),
    fork(putAudioStoppedSaga),
    fork(listEventsOnlineSaga),
    fork(listProductionsSaga),
    fork(listContributorVisitsSaga),
    fork(listWidgetVisitsSaga),
    fork(getGlobalStatsSaga),
    fork(listPodcastsPlayedSaga),
    fork(listListeningAvgSaga),
    fork(listBookingLeadsSaga),
    fork(listEventsViewSaga),
  ]);
};
