import { AppActions, AppStates } from 'interfaces';

interface IReducerMap {
  [key: string]: (...args: any[]) => void;
}

const isLoadingReducer = (state: AppStates) => ({
  ...state,
  error: null,
  loading: true,
});

const successReducer = (state: AppStates) => ({
  ...state,
  error: null,
  loading: false,
});

const errorReducer = (state: AppStates, action: AppActions) => ({
  ...state,
  error: action.payload,
  loading: false,
});

const noopReducer = (state: AppStates) => state;

export const withLoadable = (actionTypes: string[]) => {
  const actionReducerMap: IReducerMap = actionTypes.reduce(
    (acum, item) => ({
      ...acum,
      [item]: isLoadingReducer,
      [`${item}_SUCCESS`]: successReducer,
      [`${item}_FAILURE`]: errorReducer,
    }),
    {},
  );

  return (baseReducer: (...args: any[]) => void) => (state: AppStates, action: AppActions) => {
    const reducerFunction = actionReducerMap[action.type] || noopReducer;
    const newState = reducerFunction(state, action);
    return baseReducer(newState, action);
  };
};
