import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { SupportedLanguages } from '../../modules/Global/Constants';
import { ArrowCircle, FlagImage, LanguageDropdown } from './LanguageSelector.style';
import { GlobalState, ILanguage } from '../../modules/Global/Global.interfaces';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LanguageSelector = ({
  handleLanguageSelect,
  allowedLanguages,
}: {
  handleLanguageSelect: (selectedLanguage: ILanguage) => void;
  allowedLanguages?: ILanguage[];
}) => {
  const { t } = useTranslation();
  const { homePageState } = useSelector(({ globalReducer }: { globalReducer: GlobalState }) => globalReducer);
  const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(homePageState.selectedLanguage);

  useEffect(() => {
    const filteredLanguages = SupportedLanguages.filter(
      (lang) => !allowedLanguages || allowedLanguages.some((al) => al.lng === lang.lng),
    );

    const newSelectedLanguage =
      filteredLanguages.find((lang) => lang.lng === homePageState.selectedLanguage.lng) || filteredLanguages[0];
    setSelectedLanguage(newSelectedLanguage);
    handleLanguageSelect(newSelectedLanguage);
  }, [homePageState.selectedLanguage, allowedLanguages]);

  const menuItems = SupportedLanguages.filter(
    (l) => !allowedLanguages || allowedLanguages.some((al) => al.lng === l.lng),
  ).map((l) => (
    <Menu.Item
      key={l.lng}
      onClick={() => {
        setSelectedLanguage(l);
        handleLanguageSelect(l);
      }}
    >
      <FlagImage src={`/flags/${l.lng}.svg`} alt={l.code} />
      {t(l.name)}
    </Menu.Item>
  ));

  const dropdownMenu = <Menu>{menuItems}</Menu>;

  return menuItems.length > 1 ? (
    <LanguageDropdown overlay={dropdownMenu} trigger={['click']}>
      <div>
        <ArrowCircle>
          <MdOutlineKeyboardArrowRight />
        </ArrowCircle>
        <FlagImage src={`/flags/${selectedLanguage.lng}.svg`} alt={selectedLanguage.code} />
        {t(selectedLanguage.name)}
      </div>
    </LanguageDropdown>
  ) : (
    <div>
      <FlagImage src={`/flags/${selectedLanguage.lng}.svg`} alt={selectedLanguage.code} />
      {t(selectedLanguage.name)}
    </div>
  );
};

export default LanguageSelector;
