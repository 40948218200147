import { createApiAction } from 'utils/helpers';

export const POST_AUTH_LOGIN = 'POST_AUTH_LOGIN';
export const POST_AUTH_LOGIN_SUCCESS = 'POST_AUTH_LOGIN_SUCCESS';
export const POST_AUTH_LOGIN_FAILURE = 'POST_AUTH_LOGIN_FAILURE';

export const POST_AUTH_REGISTER = 'POST_AUTH_REGISTER';
export const POST_AUTH_REGISTER_SUCCESS = 'POST_AUTH_REGISTER_SUCCESS';
export const POST_AUTH_REGISTER_FAILURE = 'POST_AUTH_REGISTER_FAILURE';

export const POST_SOCIAL_AUTH = 'POST_SOCIAL_AUTH';
export const POST_SOCIAL_AUTH_SUCCESS = 'POST_SOCIAL_AUTH_SUCCESS';
export const POST_SOCIAL_AUTH_FAILURE = 'POST_SOCIAL_AUTH_FAILURE';

export const POST_EMAIL_RESET_PASSWORD = 'POST_EMAIL_RESET_PASSWORD';
export const POST_EMAIL_RESET_PASSWORD_SUCCESS = 'POST_EMAIL_RESET_PASSWORD_SUCCESS';
export const POST_EMAIL_RESET_PASSWORD_FAILURE = 'POST_EMAIL_RESET_PASSWORD_FAILURE';
export const CLEAR_EMAIL_RESET_PASSWORD_SUCCESS = 'CLEAR_EMAIL_RESET_PASSWORD_SUCCESS';

export const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
export const POST_RESET_PASSWORD_FAILURE = 'POST_RESET_PASSWORD_FAILURE';

export const GET_EMAIL_ACTIVATE = 'GET_EMAIL_ACTIVATE';
export const GET_EMAIL_ACTIVATE_SUCCESS = 'GET_EMAIL_ACTIVATE_SUCCESS';
export const GET_EMAIL_ACTIVATE_FAILURE = 'GET_EMAIL_ACTIVATE_FAILURE';

export const POST_TOKEN_VERIFY = 'POST_TOKEN_VERIFY';
export const POST_TOKEN_VERIFY_SUCCESS = 'POST_TOKEN_VERIFY_SUCCESS';
export const POST_TOKEN_VERIFY_FAILURE = 'POST_TOKEN_VERIFY_FAILURE';

export const LOG_OUT = 'LOG_OUT';

export const postAuthLogin = createApiAction(POST_AUTH_LOGIN);
export const postAuthRegister = createApiAction(POST_AUTH_REGISTER);
export const postSocialAuth = createApiAction(POST_SOCIAL_AUTH);
export const postEmailResetPassword = createApiAction(POST_EMAIL_RESET_PASSWORD);
export const postResetPassword = createApiAction(POST_RESET_PASSWORD);
export const getEmailActivate = createApiAction(GET_EMAIL_ACTIVATE);
export const postTokenVerify = createApiAction(POST_TOKEN_VERIFY);
export const logOut = createApiAction(LOG_OUT);
