import React, { FC, lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import { Route, Switch } from 'react-router-dom';
import { history } from 'utils/history';
import { useWindowSize } from 'utils/hooks';
import { Loader } from 'components/Loader';
import { getUserLanguage, setUserLanguage, urlB64ToUint8Array } from 'utils/helpers';
import envVariables, { ROUTES } from 'constant';
import { saveNotificationSubscription } from 'modules/Profile/Profile.actions';
import { ProfileState } from 'modules/Profile/Profile.interfaces';
import { AuthLayout, IframeLayout, PermissionRoute } from './layouts';

import { AuthState } from 'modules/Auth/Auth.interfaces';
import i18n from 'i18n';
import path from 'path';
import { updateIframeParams } from './modules/Global/Global.actions';
import { postWidgetView } from './modules/Statistics/Statistics.actions';
import { DefaultLanguage } from './modules/Global/Constants';
import { getContributor } from './modules/Events/Events.actions';
import { ADMIN, CONTRIBUTOR, REDACTOR, SUPER_ADMIN } from './interfaces';
import useRouteGuard from './utils/useRouteGuard';
import { LottieWrapper } from './assets/styles/Global';

history.listen(() => {
  window.scrollTo(0, 0);
});

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const ErrorPage = lazy(() => import(/* webpackChunkName: "ErrorPage" */ 'modules/404'));
const EmailActivate = lazy(() =>
  import(/* webpackChunkName: "EmailActivate" */ 'modules/Auth/EmailActivate/index').then((module) => ({
    default: module.EmailActivate,
  })),
);
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ './modules/Auth/SignIn'));
const SignUp = lazy(() =>
  import(/* webpackChunkName: "SignUp" */ 'modules/Auth/SignUp/index').then((module) => ({
    default: module.SignUp,
  })),
);
const ForgetPassWord = lazy(() =>
  import(/* webpackChunkName: "ForgetPassWord" */ 'modules/Auth/ForgetPassword/index').then((module) => ({
    default: module.ForgetPassWord,
  })),
);
const RecoverPassWord = lazy(() =>
  import(/* webpackChunkName: "RecoverPassWord" */ 'modules/Auth/RecoverPassword/index').then((module) => ({
    default: module.RecoverPassWord,
  })),
);
const Pricing = lazy(() => import(/* webpackChunkName: "Pricing" */ 'modules/Pricing/Pricing'));
const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ 'modules/Profile/Profile').then((module) => ({ default: module.Profile })),
);
const ConfirmByEmail = lazy(() =>
  import(/* webpackChunkName: "ConfirmByEmail" */ 'modules/Auth/ConfirmByEmail/index').then((module) => ({
    default: module.ConfirmByEmail,
  })),
);
const Events = lazy(() =>
  import(/* webpackChunkName: "Events" */ 'modules/Events/Events').then((module) => ({
    default: module.Events,
  })),
);
const SingleEvent = lazy(() =>
  import(/* webpackChunkName: "SingleEvent" */ 'modules/Events/SingleEvent').then((module) => ({
    default: module.SingleEvent,
  })),
);
const ContributorInfoPage = lazy(() =>
  import(/* webpackChunkName: "ContributorInfoPage" */ 'modules/ContributorInfoPage').then((module) => ({
    default: module.ContributorInfoPage,
  })),
);
const UploadCsv = lazy(() =>
  import(/* webpackChunkName: "UploadCsv" */ 'modules/AdminPanel/UploadCsv').then((module) => ({
    default: module.UploadCsv,
  })),
);

export const Routes: FC = () => {
  useRouteGuard();
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const [animating, setAnimating] = useState<boolean>(!Boolean(sessionStorage.getItem('reloaded')));
  const { subscribeToPush } = useSelector(({ profileReducer }: { profileReducer: ProfileState }) => profileReducer);
  const { loggedIn } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);
  window.addEventListener('beforeunload', () => sessionStorage.setItem('reloaded', '+'));
  const [token, setToken] = useState<string | null>();
  const [animation, setAnimation] = useState();

  const isIframe = window.self !== window.top;
  useEffect(() => {
    async function fetchAnimation() {
      const response = await fetch(envVariables.lottieAnimation);
      const anim = await response.json();
      setAnimation(anim);
    }
    fetchAnimation();
    setToken(localStorage.getItem('token'));
    if (!sessionStorage.getItem('reloaded')) {
      setAnimating(true);
    }
    if (isIframe) {
      setUserLanguage(DefaultLanguage);
      const contributorId = path.basename(window.location.pathname);
      if (contributorId) {
        dispatch(getContributor.request({ id: contributorId }));
        dispatch(postWidgetView.request({ user_id: contributorId, lng: getUserLanguage().id }));
        dispatch(updateIframeParams({ isIframe: isIframe, contributorId: parseInt(contributorId) }));
      }
    }
    const language = getUserLanguage();
    i18n.changeLanguage(language.lng);

    return () => setAnimating(false);
  }, []);

  useEffect(() => {
    if (animating) {
      if (envVariables.lottieAudio) {
        const audio = new Audio(envVariables.lottieAudio);

        audio.play().catch((error) => {
          console.log(error);
        });
      }
    }
  }, [animating]);

  useEffect(() => {
    if ('Notification' in window) {
      if (
        (subscribeToPush || Notification.permission !== 'granted') &&
        (token || loggedIn) &&
        'serviceWorker' in navigator
      ) {
        navigator.serviceWorker.ready.then((reg) => {
          if (process.env.REACT_APP_PUSH_PUBLIC_KEY) {
            const publicKey = urlB64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);

            reg.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: publicKey,
              })
              .then((sub) => {
                dispatch(saveNotificationSubscription.request({ sub: sub.toJSON() }));
              })
              .catch(() => {
                if (Notification.permission === 'denied') {
                  console.log({ text: 'You will not recieve notifications' });
                } else {
                  console.log({ text: 'Please allow notifications' });
                }
              });
          }
        });
      }
    }
  }, [subscribeToPush, loggedIn, token]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return isIframe ? (
    <IframeLayout>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route key={ROUTES.BASE.SINGLE_EVENT} path={ROUTES.BASE.SINGLE_EVENT} component={SingleEvent} />
          <Route key={ROUTES.BASE.WIDGET_EVENTS} path={ROUTES.BASE.WIDGET_EVENTS} exact={true} component={Events} />
          <Route key={ROUTES.BASE.EVENTS} path={ROUTES.BASE.EVENTS} exact={true} component={Events} />
          <Route key={ROUTES.BASE.ERROR_PAGE} path={ROUTES.BASE.ERROR_PAGE} component={ErrorPage} />
        </Switch>
      </Suspense>
    </IframeLayout>
  ) : (
    <>
      {animating && (
        <LottieWrapper>
          <Lottie
            options={defaultOptions}
            height={height}
            width={width}
            style={{ zIndex: 100 }}
            eventListeners={[
              {
                eventName: 'loopComplete',
                callback: () => setAnimating(false),
              },
            ]}
          />
        </LottieWrapper>
      )}
      {!animating && (
        <AuthLayout>
          <Suspense fallback={<Loader />}>
            <Switch>
              <PermissionRoute
                key={ROUTES.AUTH.LOGIN_PAGE}
                path={ROUTES.AUTH.LOGIN_PAGE}
                exact={true}
                component={SignIn}
              />

              <PermissionRoute
                key={ROUTES.AUTH.REGISTRATION_PAGE}
                path={ROUTES.AUTH.REGISTRATION_PAGE}
                exact={true}
                component={SignUp}
              />
              <PermissionRoute
                key={ROUTES.AUTH.FORGET_PASSWORD_PAGE}
                path={ROUTES.AUTH.FORGET_PASSWORD_PAGE}
                exact={true}
                component={ForgetPassWord}
              />
              <PermissionRoute
                key={ROUTES.AUTH.RECOVER_PASSWORD}
                path={ROUTES.AUTH.RECOVER_PASSWORD}
                exact={false}
                component={RecoverPassWord}
              />
              <PermissionRoute
                key={ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}
                path={ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}
                exact={false}
                component={Profile}
              />
              <PermissionRoute
                key={ROUTES.PROFILE.MY_ENTITY}
                path={ROUTES.PROFILE.MY_ENTITY}
                exact={true}
                component={Profile}
                permissions={[CONTRIBUTOR, ADMIN, SUPER_ADMIN]}
              />
              <PermissionRoute
                key={ROUTES.BASE.PRICING_PLAN_PAGE}
                path={ROUTES.BASE.PRICING_PLAN_PAGE}
                exact={true}
                component={Pricing}
              />
              <PermissionRoute
                key={ROUTES.AUTH.CONFIRM_BY_EMAIL}
                path={ROUTES.AUTH.CONFIRM_BY_EMAIL}
                exact={true}
                component={ConfirmByEmail}
              />
              <PermissionRoute
                key={ROUTES.PROFILE.EMAIL_ACTIVATE}
                path={ROUTES.PROFILE.EMAIL_ACTIVATE}
                exact={true}
                component={EmailActivate}
              />
              <PermissionRoute
                key={ROUTES.EVENTS.REGIONS}
                path={ROUTES.EVENTS.REGIONS}
                exact={true}
                component={Events}
              />
              <PermissionRoute key={ROUTES.EVENTS.VILLES} path={ROUTES.EVENTS.VILLES} exact={true} component={Events} />
              <PermissionRoute key={ROUTES.BASE.EVENTS} path={ROUTES.BASE.EVENTS} exact={true} component={Events} />
              <PermissionRoute key={ROUTES.BASE.SINGLE_EVENT} path={ROUTES.BASE.SINGLE_EVENT} component={SingleEvent} />
              <PermissionRoute
                key={ROUTES.BASE.CONTRIBUTOR_PAGE}
                path={ROUTES.BASE.CONTRIBUTOR_PAGE}
                component={ContributorInfoPage}
              />
              <PermissionRoute
                key={ROUTES.BASE.CONTRIBUTOR_PAGE_ID}
                path={ROUTES.BASE.CONTRIBUTOR_PAGE_ID}
                component={ContributorInfoPage}
              />
              <PermissionRoute
                key={ROUTES.ADMIN_PANEL.UPLOAD_CSV}
                path={ROUTES.ADMIN_PANEL.UPLOAD_CSV}
                exact={true}
                component={UploadCsv}
                permissions={[REDACTOR, ADMIN, SUPER_ADMIN]}
              />
              <PermissionRoute key={ROUTES.BASE.ERROR_PAGE} path={ROUTES.BASE.ERROR_PAGE} component={ErrorPage} />
            </Switch>
          </Suspense>
        </AuthLayout>
      )}
    </>
  );
};
