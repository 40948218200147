import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import { history } from 'utils/history';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constant';
import { logOut } from 'modules/Auth/Auth.actions';
import { ADMIN, BASE_USER, CONTRIBUTOR, IMenuList, PRODUCER, REDACTOR, SUPER_ADMIN } from 'interfaces';
import { AuthState } from 'modules/Auth/Auth.interfaces';
import { RenderMenuList } from 'components/MenuList';

export const MobileMenu = ({ className, onClose }: { className: string; onClose: () => void }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loggedIn } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);
  const handleLogOut = () => {
    dispatch(logOut.request());
    history.push(ROUTES.AUTH.LOGIN_PAGE);
    onClose();
  };

  const MenuList: IMenuList[] = [
    {
      key: 'AGENT_ACCOUNT_SETTINGS_PAGE',
      exact: true,
      to: ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE,
      onClick: onClose,
      title: t('Account Settings'),
    },
    {
      key: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_ENTITY}`,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_ENTITY}`,
      onClick: onClose,
      title: t('My Entity'),
      permissions: [CONTRIBUTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'ENTITIES_EVENTS',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}`,
      title: t('My events'),
      onClick: onClose,
      permissions: [CONTRIBUTOR],
    },
    {
      key: 'FAVORITES',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.FAVORITES}`,
      title: t('My favorites'),
      onClick: onClose,
      permissions: [BASE_USER, CONTRIBUTOR],
    },
    {
      key: 'INVOICES',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.INVOICES}`,
      title: t('Invoices'),
      onClick: onClose,
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'MY-INVOICES',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_PLAN}`,
      title: t('My subscription'),
      onClick: onClose,
      permissions: [CONTRIBUTOR],
    },
    {
      key: 'GENERAL_STATS',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.GENERAL_STATISTICS}`,
      title: t('Statistics'),
      onClick: onClose,
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'USERS_ADMIN_PANEL',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.USERS_ADMIN_PANEL}`,
      title: t('Users'),
      onClick: onClose,
      permissions: [REDACTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'ENTITIES_ADMIN_PANEL',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.ENTITIES_ADMIN_PANEL}`,
      title: t('Entities'),
      onClick: onClose,
      permissions: [REDACTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'REDACTOR_PAGE',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}`,
      title: t('Events'),
      onClick: onClose,
      permissions: [PRODUCER, REDACTOR, ADMIN, SUPER_ADMIN],
    },
    {
      key: 'TAGS',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.TAGS}`,
      title: t('Tags'),
      onClick: onClose,
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'PHONETICS',
      onClick: onClose,
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.PHONETICS}`,
      title: t('Phonetics'),
      permissions: [SUPER_ADMIN],
    },
    {
      key: 'EVENTS',
      exact: true,
      to: ROUTES.BASE.EVENTS,
      onClick: onClose,
      title: t('Read Events'),
    },
    {
      key: 'CREATE_EVENTS',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}${ROUTES.PROFILE.CREATE_EVENT}`,
      onClick: onClose,
      title: t('Create event'),
    },
    {
      key: 'PRICING_PLAN_PAGE',
      exact: true,
      to: ROUTES.BASE.PRICING_PLAN_PAGE,
      onClick: onClose,
      title: t('Pricing'),
    },
  ];

  const LoggedOutMenuList: IMenuList[] = [
    {
      key: 'EVENTS',
      exact: true,
      to: ROUTES.BASE.EVENTS,
      onClick: onClose,
      title: t('Events'),
    },
    {
      key: 'PRICING_PLAN_PAGE',
      exact: true,
      to: ROUTES.BASE.PRICING_PLAN_PAGE,
      onClick: onClose,
      title: t('Pricing'),
    },
  ];

  return (
    <>
      {/* {(loggedIn || localStorage.getItem('token') || localStorage.getItem('refresh')) && ( */}
      <Menu className={className}>
        {RenderMenuList(
          loggedIn || localStorage.getItem('token') || localStorage.getItem('refresh') ? MenuList : LoggedOutMenuList,
        )}
        {(loggedIn || localStorage.getItem('token') || localStorage.getItem('refresh')) && (
          <Menu.Item key="handleLogOut">
            <button onClick={handleLogOut}>{t('Log Out')}</button>
          </Menu.Item>
        )}
      </Menu>
      {/* )} */}
    </>
  );
};
