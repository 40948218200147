import * as Action from './Statistics.actions';
import { Reducer } from 'react';
import { StatisticsActionTypes, StatisticsState } from './Statistics.interfaces';
import { withLoadable } from '../../utils/hoc';

const initialState: StatisticsState = {
  audio_played_id: null,
  stats_data: null,
  global_stats: null,
};

const reducer: Reducer<StatisticsState, StatisticsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.POST_AUDIO_PLAYED_SUCCESS: {
      return {
        ...state,
        audio_played_id: action.payload.id,
      };
    }
    case Action.LIST_EVENTS_ONLINE_SUCCESS:
    case Action.LIST_PRODUCTIONS_SUCCESS:
    case Action.LIST_CONTRIBUTOR_VISITS_SUCCESS:
    case Action.LIST_WIDGET_VISITS_SUCCESS:
    case Action.LIST_EVENTS_VIEW_SUCCESS:
    case Action.LIST_PODCAST_PLAYED_SUCCESS:
    case Action.LIST_LISTENING_AVG_SUCCESS:
    case Action.LIST_BOOKING_LEADS_SUCCESS: {
      return {
        ...state,
        stats_data: action.payload,
      };
    }
    case 'GET_GLOBAL_STATS_SUCCESS':
      return {
        ...state,
        global_stats: action.payload,
      };
    default:
      return state;
  }
};

export const statisticsReducer = withLoadable([Action.POST_AUDIO_PLAYED])(reducer);
