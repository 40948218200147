import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const AudioWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 999999;
  width: 100%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  background: #ffffffe4;
  backdrop-filter: blur(2px);

  .rhap_container {
    padding: 10px 0;
    box-shadow: none;
    background-color: transparent;
    overflow: hidden;
    @media only screen and (max-width: 480px) {
      padding: 0;
    }
  }

  .rhap_additional-controls,
  .rhap_volume-controls {
    justify-content: space-around;
  }

  .rhap_progress-section {
    flex-wrap: wrap;

    .transcription-wrapper {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .transcription-toggle {
        background: #ffffffe4;
        margin-right: 0;
        margin-left: auto;
        z-index: 2;
      }
    }
  }

  .rhap_volume-indicator,
  .rhap_progress-indicator {
    background: ${themeGet('primary.5')};
    width: 10px;
  }

  .rhap_repeat-button,
  .rhap_volume-button,
  .rhap_main-controls-button {
    color: ${themeGet('primary.5')};
  }

  @media only screen and (max-width: 480px) {
    .rhap_additional-controls {
      justify-content: start;
    }

    .rhap_volume-controls {
      justify-content: end;
    }
  }
`;

export const TranscriptionPlayer = styled.div`
  color: ${themeGet('text.0', '#2C2C2C')};
  font-size: 17px;
  font-weight: 700;
  white-space: normal;
  min-width: 0;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const CardWrapper = styled.div`
  padding: 10px 0;
  min-width: 270px;
  max-width: 270px;
  display: flex;

  @media only screen and (max-width: 600px) {
    min-width: 140px;
    max-width: 100%;

    img {
      display: none;
    }
  }
`;

export const TitleArea = styled.div`
  color: ${themeGet('text.0', '#2C2C2C')};
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 2px;
  white-space: nowrap;
  width: 100%;
  margin: 0;

  :hover {
    cursor: pointer;
  }

  @keyframes demo-1 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-120%);
    }
  }

  @media only screen and (min-width: 480px) {
    text-align: center;
    transform: translateX(100%);
    animation: demo-1 8s linear infinite;
  }

  @media only screen and (max-width: 480px) {
    font-size: 14px;
    margin: 4px 0 5px;
  }
`;

export const PriceArea = styled.div`
  color: ${themeGet('text.0', '#2C2C2C')};
  font-size: 13px;
  font-weight: 300;
  margin-top: 1px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const DescriptionCard = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const ImgWrapper = styled.img`
  width: 120px;
  height: 80px;
  object-fit: contain;
  position: relative;
  margin-right: 10px;

  :hover {
    cursor: pointer;
  }
`;

export const FLexWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
