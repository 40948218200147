import React, { ReactNode } from 'react';
import NavbarWrapper, { AuthWrapper, AvatarWrapper, Container, LogoArea, MenuArea, MenuWrapper } from './Navbar.style';
import { ContainerWrapper } from 'assets/styles/Container.style';

interface INavbar {
  className?: string;
  logo: ReactNode;
  navMenu: ReactNode;
  authMenu: ReactNode;
  profileMenu: ReactNode;
  isLogin: boolean;
  headerType: string;
}

export const Navbar: React.FC<INavbar> = ({
  className,
  logo,
  navMenu,
  authMenu,
  profileMenu,
  isLogin,
  headerType,
}): JSX.Element => {
  const addAllClasses = ['navbar'];
  if (className) {
    addAllClasses.push(className);
  }
  if (headerType) {
    addAllClasses.push(`is_${headerType}`);
  }

  return (
    <NavbarWrapper className={addAllClasses.join(' ')}>
      <Container as={ContainerWrapper}>
        {logo ? <LogoArea>{logo}</LogoArea> : null}
        <MenuArea>
          {navMenu && <MenuWrapper className="main_menu">{navMenu}</MenuWrapper>}
          {isLogin && <AvatarWrapper>{profileMenu}</AvatarWrapper>}
          {authMenu && <AuthWrapper className="auth_menu">{authMenu}</AuthWrapper>}
        </MenuArea>
      </Container>
    </NavbarWrapper>
  );
};
