import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routesCustomization } from '../layouts/Router/routesCustomization';
import { isExcludedRoute } from './helpers';
import { useSelector } from 'react-redux';
import { AuthState } from '../modules/Auth/Auth.interfaces';

const useRouteGuard = () => {
  const location = useLocation();
  const history = useHistory();
  const env = process.env.REACT_APP_ENV || 'anto';
  const { loggedIn } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);
  useEffect(() => {
    const path = location.pathname;
    const customerRoutes = routesCustomization[env];

    if (isExcludedRoute(path, customerRoutes)) {
      const customHomepage = customerRoutes?.customHomepage;
      if (path === '/' && customHomepage) {
        if (loggedIn) {
          history.replace(customHomepage.loggedIn);
        } else {
          history.replace(customHomepage.anonymous);
        }
      } else {
        history.replace('/404'); // Redirect to 404 page
      }
    }
  }, [location, env, history]);
};

export default useRouteGuard;
