import styled from 'styled-components';

const LogoArea = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }

  img {
    height: 58px;
  }

  &.desktop-header {
    img {
      margin-top: 31px;
      margin-bottom: 31px;
    }
  }

  &.mobile-header {
    img {
      height: 40px;
    }
  }
`;

export default LogoArea;
