import { axiosInstance } from 'services/api';
import {
  IAudioPlayed,
  IBookingLead,
  IContributorView,
  IEventView,
  IFilterParams,
  IWidgetView,
} from './Statistics.interfaces';

export default {
  async postBookingLead(payload: IBookingLead) {
    return axiosInstance.post('statistic/booking-leads/', payload);
  },
  async listEventsOnline(payload: IFilterParams) {
    return axiosInstance.get('statistic/events-online/', { params: payload });
  },
  async listEventsView(payload: IFilterParams) {
    return axiosInstance.get('statistic/events/', { params: payload });
  },
  async listProductions(payload: IFilterParams) {
    return axiosInstance.get('statistic/productions/', { params: payload });
  },
  async listContributorVisits(payload: IFilterParams) {
    return axiosInstance.get('statistic/contributor-visits/', { params: payload });
  },
  async listWidgetVisits(payload: IFilterParams) {
    return axiosInstance.get('statistic/widget-visits/', { params: payload });
  },
  async getGlobalStats(payload: IFilterParams) {
    return axiosInstance.get('statistic/global-stats/', { params: payload });
  },
  async listPodcastsPlayed(payload: IFilterParams) {
    return axiosInstance.get('statistic/podcasts-played/', { params: payload });
  },
  async listListeningAvg(payload: IFilterParams) {
    return axiosInstance.get('statistic/listening-avg/', { params: payload });
  },
  async listBookingLeads(payload: IFilterParams) {
    return axiosInstance.get('statistic/booking-leads/', { params: payload });
  },
  async postEventView(payload: IEventView) {
    return axiosInstance.post('statistic/events/', payload);
  },
  async postWidgetView(payload: IWidgetView) {
    return axiosInstance.post('statistic/widget-visits/', payload);
  },
  async postContributorView(payload: IContributorView) {
    return axiosInstance.post('statistic/contributor-visits/', payload);
  },
  async postAudioPlayed(payload: IAudioPlayed) {
    return axiosInstance.post('statistic/audio-played/', payload);
  },
  async putAudioStopped(payload: IAudioPlayed) {
    return axiosInstance.put(`statistic/audio-stopped/${payload.id}/`, payload);
  },
};
