import React from 'react';

export const BackIcon = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 46.18 28.69">
    <defs>
      <clipPath id="clip-path" transform="translate(-32.46 -41.56)">
        <rect className="cls-1" fill={fill} width="111.1" height="111.1" />
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_5" data-name="Capa 5">
        <g className="cls-2" clipPath="url(#clip-path)">
          <path
            className="cls-3"
            fill={fill}
            d="M53,70.25c-1.84,0-20.54-11.79-20.54-13.93S51.14,41.56,53.05,41.56C56.39,41.55,56.39,70.25,53,70.25Z"
            transform="translate(-32.46 -41.56)"
          />
          <path
            className="cls-3"
            fill={fill}
            d="M76.09,70.25c-1.84,0-20.54-11.79-20.54-13.93s18.68-14.76,20.6-14.76C79.48,41.55,79.48,70.25,76.09,70.25Z"
            transform="translate(-32.46 -41.56)"
          />
        </g>
      </g>
    </g>
  </svg>
);
