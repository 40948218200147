import { all, fork } from 'redux-saga/effects';
import { authSaga } from 'modules/Auth/Auth.sagas';
import { eventsSaga } from 'modules/Events/Events.sagas';
import { profileSaga } from 'modules/Profile/Profile.sagas';
import { usersSaga } from 'modules/AdminPanel/AdminPanel.sagas';
import { pricingSaga } from 'modules/Pricing/Pricing.sagas';
import { sitSaga } from 'modules/SIT/SIT.sagas';
import { seoLinksSaga } from 'modules/SEOLinking/SEOLinking.sagas';
import { statisticSaga } from '../modules/Statistics/Statistics.sagas';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(eventsSaga),
    fork(profileSaga),
    fork(usersSaga),
    fork(pricingSaga),
    fork(sitSaga),
    fork(seoLinksSaga),
    fork(statisticSaga),
  ]);
}
