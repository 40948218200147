import { createBrowserHistory } from 'history';
import { routesCustomization } from '../layouts/Router/routesCustomization';
import { getUserInfo, isExcludedRoute } from './helpers';

const baseHistory = createBrowserHistory();
const customerID = process.env.REACT_APP_ENV as string;

export const history = {
  ...baseHistory,
  push: (path: string, state?: any) => {
    const user = getUserInfo();
    const customerRoutes = routesCustomization[customerID];

    if (isExcludedRoute(path, customerRoutes)) {
      const customHomepage = customerRoutes?.customHomepage;
      if (path === '/' && customHomepage) {
        if (user.email) {
          baseHistory.push(customHomepage.loggedIn, state);
        } else {
          baseHistory.push(customHomepage.anonymous, state);
        }
      } else {
        baseHistory.push('/404', state); // Redirect to 404 page
      }
    } else {
      baseHistory.push(path, state);
    }
  },
};
