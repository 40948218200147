import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getUserLanguage } from '../utils/helpers';

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  responseType: 'json',
  headers: { 'Accept-Language': getUserLanguage().code, 'X-Client-ID': process.env.REACT_APP_CLIENT_ID },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config.url == '/token/refresh/') {
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_BASE_URL}sign-in`);
    }
    return Promise.reject(error);
  },
);

const refreshAuthLogic = (failedRequest: any) => {
  const refresh = localStorage.getItem('refresh');
  return axiosInstance.post('/token/refresh/', { refresh }).then((response) => {
    const { access, refresh } = response.data;
    localStorage.setItem('token', access);
    localStorage.setItem('refresh', refresh);
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + access;
    return Promise.resolve();
  });
};

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export { axiosInstance };
