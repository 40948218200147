import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from './SIT.actions';
import * as ActionInterface from './SIT.interfaces';
import Api from './SIT.api';

function* getApidaeInfo({ payload }: ActionInterface.GetAPIDAEInfoAction) {
  // if invalid email is passed the server still returns success
  try {
    const { data } = yield call(Api.getAPIDAEInfo, payload);
    if (data) {
      yield put(ActionTypes.getAPIDAEInfo.success(data));
    } else {
      yield put(ActionTypes.getAPIDAEInfo.failure(Error('Invalid email')));
    }
  } catch (e) {
    yield put(ActionTypes.getAPIDAEInfo.failure(e));
  }
}

function* getUserApidaeInfoSaga() {
  yield takeLatest(ActionTypes.GET_APIDAE_INFO, getApidaeInfo);
}

function* getTourinsoftInfo({ payload }: ActionInterface.GetTourinsoftInfoAction) {
  try {
    const { data } = yield call(Api.getTourinsoftInfo, payload);
    if (data) {
      yield put(ActionTypes.getTourinsoftInfo.success());
    } else {
      yield put(ActionTypes.getTourinsoftInfo.failure(Error('Invalid data provided')));
    }
  } catch (e) {
    yield put(ActionTypes.getTourinsoftInfo.failure(e));
  }
}

function* getTourinsoftInfoSaga() {
  yield takeLatest(ActionTypes.GET_TOURINSOFT_INFO, getTourinsoftInfo);
}

function* getOpenagendaInfo({ payload }: ActionInterface.GetOpenAgendaInfoAction) {
  try {
    const { data } = yield call(Api.getOpenagendaInfo, payload);
    if (data) {
      yield put(ActionTypes.getOpenAgendaInfo.success());
    } else {
      yield put(ActionTypes.getOpenAgendaInfo.failure(Error('Invalid data provided')));
    }
  } catch (e) {
    yield put(ActionTypes.getOpenAgendaInfo.failure(e));
  }
}

function* getOpenagendaInfoSaga() {
  yield takeLatest(ActionTypes.GET_OPENAGENDA_INFO, getOpenagendaInfo);
}

export const sitSaga = function* rootSaga() {
  yield all([fork(getUserApidaeInfoSaga), fork(getTourinsoftInfoSaga), fork(getOpenagendaInfoSaga)]);
};
