import { Reducer } from 'react';
import { withLoadable } from 'utils/hoc';
import * as Action from './Pricing.actions';
import { PricingActionTypes, PricingState } from './Pricing.interfaces';

const initialState: PricingState = {
  loading: false,
  error: null,
  plans: [],
  packs: [],
  currentPlan: null,
  selectedPlan: {},
  sessionId: null,
  offersDescriptions: [],
};

const reducer: Reducer<PricingState, PricingActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_PLANS_SUCCESS: {
      return {
        ...state,
        plans: action.payload.results,
      };
    }

    case Action.GET_ALL_PACK_SUCCESS: {
      return {
        ...state,
        packs: action.payload.results,
      };
    }

    case Action.GET_CURRENT_PLAN_SUCCESS: {
      return {
        ...state,
        currentPlan: Boolean(action.payload.price) ? action.payload : null,
      };
    }
    case Action.DELETE_CURRENT_PLAN_SUCCESS: {
      return {
        ...state,
        currentPlan: null,
      };
    }
    case Action.SET_ID_OF_PLAN: {
      return {
        ...state,
        selectedPlan: { ...action.payload },
      };
    }

    case Action.GET_PAYMENT_PLAN_SUCCESS: {
      return {
        ...state,
        sessionId: action.payload,
      };
    }
    case Action.GET_PAYMENT_PACK_SUCCESS: {
      return {
        ...state,
        sessionId: action.payload,
      };
    }
    case Action.CLEAR_SESSION_ID: {
      return {
        ...state,
        sessionId: null,
      };
    }
    case Action.CLEAR_CURRENT_PLAN: {
      return {
        ...state,
        currentPlan: null,
      };
    }
    case Action.GET_OFFERS_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        offersDescriptions: action.payload,
      };
    }
    case Action.PUT_OFFERS_DESCRIPTIONS_SUCCESS: {
      return {
        ...state,
        offersDescriptions: action.payload,
      };
    }

    default:
      return state;
  }
};

export const pricingReducer = withLoadable([
  Action.GET_PLANS,
  Action.GET_ALL_PACK,
  Action.GET_PAYMENT_PACK,
  Action.GET_PAYMENT_PLAN,
  Action.GET_CURRENT_PLAN,
  Action.DELETE_CURRENT_PLAN,
  Action.GET_PAYMENT_FAILED,
  // Action.GET_OFFERS_DESCRIPTION,
])(reducer);
