import { ADMIN, BASE_USER, CONTRIBUTOR, IStatus, PRODUCER, REDACTOR, SUPER_ADMIN } from '../interfaces';

type StatusColor = {
  text: string;
  backgroundColor: string;
  borderColor: string;
};

export const StatusColors: Record<IStatus, StatusColor> = {
  CREATED: { text: '#104FF1', backgroundColor: '#DCE5FD', borderColor: '#B0C4FF' },
  ON_HOLD: { text: '#F8AD0C', backgroundColor: '#FFF9D6', borderColor: '#E18E09' },
  IN_PRODUCTION: { text: '#E67E22', backgroundColor: '#FFF2CC', borderColor: '#E67E22' },
  PRODUCED: { text: '#DCE5FD', backgroundColor: '#E5E4E4', borderColor: '#E5E4E4' },
  ONLINE: { text: '#2DAC0E', backgroundColor: '#E6F9D6', borderColor: '#2DAC0E' },
  OFFLINE: { text: '#5D5C5C', backgroundColor: '#E5E4E4', borderColor: '#4B4B4B' },
  REJECTED: { text: '#E20916', backgroundColor: '#FCD0D0', borderColor: '#D60505' },
  REJECTED_PRODUCTION: { text: '#E20916', backgroundColor: '#FEE8E8', borderColor: '#A50000' },
};

export const EventStatus = {
  CREATED: 'CREATED',
  ON_HOLD: 'ON_HOLD',
  IN_PRODUCTION: 'IN_PRODUCTION',
  PRODUCED: 'PRODUCED',
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  REJECTED: 'REJECTED',
  REJECTED_PRODUCTION: 'REJECTED_PRODUCTION',
};
export const UserRoles = {
  REDACTOR: REDACTOR,
  PRODUCER: PRODUCER,
  SUPER_ADMIN: SUPER_ADMIN,
  ADMIN: ADMIN,
  CONTRIBUTOR: CONTRIBUTOR,
  BASE_USER: BASE_USER,
};

export const StatusOptions: Record<string, IStatus[]> = {
  contributor: ['CREATED', 'ON_HOLD', 'ONLINE', 'OFFLINE', 'REJECTED'],
  redactor: ['ON_HOLD', 'CREATED', 'IN_PRODUCTION', 'PRODUCED', 'ONLINE', 'OFFLINE', 'REJECTED', 'REJECTED_PRODUCTION'],
  moderator: ['ON_HOLD', 'CREATED', 'IN_PRODUCTION', 'PRODUCED', 'ONLINE', 'OFFLINE', 'REJECTED'],
  producer: ['IN_PRODUCTION', 'REJECTED_PRODUCTION'],
  admin: ['ON_HOLD', 'CREATED', 'IN_PRODUCTION', 'PRODUCED', 'ONLINE', 'OFFLINE', 'REJECTED'],
  'super admin': [
    'ON_HOLD',
    'CREATED',
    'IN_PRODUCTION',
    'PRODUCED',
    'ONLINE',
    'OFFLINE',
    'REJECTED',
    'REJECTED_PRODUCTION',
  ],
};

export const AudioLimits = {
  size: 5,
  podcastTime: 20,
  podcastMinTime: 10,
  commentTime: 10,
  errorMargin: 0.5,
};
export const StaticPagesWP = {
  AboutUs: 'https://www.anto.info/qui-sommes-nous/',
  TermsOfPayment: 'https://www.anto.info/cgv',
  TermsOfUse: 'https://www.anto.info/cgu',
  LegalPage: 'https://www.anto.info/mentions-legales',
  Help: 'https://www.anto.info/contactez-nous/',
};

export const urlPattern = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
