import { axiosInstance } from 'services/sitApi';
import { IAPIDAEUserInfo } from './SIT.interfaces';

const APIDAE_PROJECT_ID = process.env.REACT_APP_APIDAE_PROJECT_ID;
const APIDAE_BASE_URL = process.env.REACT_APP_APIDAE_API_ENDPOINT;
const APIDAE_PROJECT_KEY = process.env.REACT_APP_APIDAE_PROJECT_KEY;

const TOURINSOFT_BASE_URL = 'https://api-v3.tourinsoft.com/';
const OPENAGENDA_BASE_URL = 'https://api.openagenda.com/v2/agendas/';

export default {
  async getAPIDAEInfo(email: string) {
    return axiosInstance.get<IAPIDAEUserInfo>(`api/v002/utilisateur/get-by-mail/${email}`, {
      params: { projetId: APIDAE_PROJECT_ID, apiKey: APIDAE_PROJECT_KEY },
      baseURL: APIDAE_BASE_URL,
    });
  },
  async getTourinsoftInfo(payload: { clientDomain: string; syndicationId: string }) {
    return axiosInstance.get(`api/syndications/${payload.clientDomain}/${payload.syndicationId}`, {
      params: { format: 'json' },
      baseURL: TOURINSOFT_BASE_URL,
    });
  },
  async getOpenagendaInfo(payload: { agendaUid: string; publicKey: string }) {
    return axiosInstance.get(`${payload.agendaUid}/events?key=${payload.publicKey}`, {
      params: { format: 'json' },
      baseURL: OPENAGENDA_BASE_URL,
    });
  },
};
