import React from 'react';

export const ForwardIcon = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 46.18 28.69">
    <defs>
      <clipPath id="clip-path" transform="translate(-34.21 -41.42)">
        <rect className="cls-1" fill="none" width="111.1" height="111.1" />
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_5" data-name="Capa 5">
        <g className="cls-2" clipPath="url(#clip-path)">
          <path
            className="cls-3"
            fill={fill}
            d="M59.85,41.42c1.85,0,20.55,11.79,20.55,13.93S61.72,70.11,59.8,70.11C56.46,70.11,56.46,41.42,59.85,41.42Z"
            transform="translate(-34.21 -41.42)"
          />
          <path
            className="cls-3"
            fill={fill}
            d="M36.76,41.42c1.84,0,20.55,11.79,20.55,13.93S38.62,70.11,36.71,70.11C33.37,70.11,33.37,41.42,36.76,41.42Z"
            transform="translate(-34.21 -41.42)"
          />
        </g>
      </g>
    </g>
  </svg>
);
